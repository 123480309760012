import { useEffect, useState, useRef } from "react";
import classes from "./SubSecondBanner.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import { useTranslation, Trans } from "react-i18next";
import img1 from "../../../assets/img/main/3/1.png"
import img2 from "../../../assets/img/main/3/2.png"
import img4 from "../../../assets/img/main/3/plan.png"

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const SubSecondBanner = ({ formOpened, setFormOpened }) => {
    const { t } = useTranslation();
    gsap.config({
        force3D: true,
    });

    const col1 = useRef(null);
    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);


    const monetRefMob = useRef(null);

    useEffect(() => {
        gsap.timeline().to(monetRefMob.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            scale: 1,
            x: 0
        });
        gsap.timeline().to(monetRef1.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            scale: 1,
            x: 0
        });
        gsap.timeline().to(monetRef2.current, {
            scrollTrigger: {
                trigger: col1.current,
                start: "top center",
                end: "top top",
                scrub: 10,
            },
            x: 0,
            y: 0,
            scale: 1
        });



    }, []);

    return (
        <div className={[classes.sub, "mb"].join(" ")} ref={col1}>
            <div className={[classes.subBody, ""].join(" ")}>
                <div className={[classes.subImg1, ""].join(" ")} ref={monetRef1}>
                    <img src={img1} alt="Retention Manager" />
                </div>
                <div className={[classes.subImg2, ""].join(" ")}>
                    <img ref={monetRef2} src={img2} alt="Retention Manager" />
                </div>


                <div className="container">
                    <div className={[classes.subRow, ""].join(" ")}>
                        <div className={[classes.subRowLeft, ""].join(" ")}>
                            <div className={[classes.subRowLeftContent, ""].join(" ")}>
                                <h2 className={[classes.subRowLeftContentTitle, "font-64"].join(" ")}>
                                    <Trans>vacancy_4_left1</Trans>
                                </h2>
                                <p className={[classes.subRowLeftContentSubTitle, "font-24"].join(" ")}>
                                    <Trans>vacancy_4_left2</Trans>
                                </p>
                                <div className={[classes.subRowLeftContentBtn, ""].join(" ")}  >
                                    <Button setFormOpened={setFormOpened} login={"true"}><Trans>apply_btn</Trans></Button>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.subRowRight, ""].join(" ")}>
                            <div className={[classes.subRowRightColumn, ""].join(" ")}>
                                <h3 className={[classes.subRowRightColumnTitle, "font-24"].join(" ")}>
                                    <Trans>vacancy_4_right1</Trans>
                                </h3>
                                <ul className={[classes.subRowRightColumnList, ""].join(" ")}>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right2</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right3</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right4</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right5</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right6</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right7</Trans>
                                    </li>
                                </ul>

                            </div>
                            <div className={[classes.subRowRightColumn, ""].join(" ")}>
                                <h3 className={[classes.subRowRightColumnTitle, "font-24"].join(" ")}>
                                    <Trans>vacancy_4_right8</Trans>
                                </h3>
                                <ul className={[classes.subRowRightColumnList, ""].join(" ")}>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right9</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right10</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right11</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right12</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right13</Trans>
                                    </li>
                                    <li className={[classes.subRowRightColumnListItem, "font-16"].join(" ")}>
                                        <Trans>vacancy_4_right14</Trans>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={[classes.subPlanImg, ""].join(" ")} ref={monetRefMob}>
                    <img src={img4} alt="Retention Manager" />
                </div>

            </div>
        </div>
    )
}

export default SubSecondBanner