import React, { useState } from 'react'
import classes from "./Faq.module.scss"
import { useTranslation, Trans } from "react-i18next";

const Faq = () => {
    const { t } = useTranslation();

    const [items, setItems] = useState([
        { id: 1, title: "main_7_item11", text: "main_7_item12", active: false },
        { id: 2, title: "main_7_item21", text: "main_7_item22", active: false },
        { id: 3, title: "main_7_item31", text: "main_7_item32", active: false },
        { id: 4, title: "main_7_item41", text: "main_7_item42", active: false },
        { id: 5, title: "main_7_item51", text: "main_7_item52", active: false },
        { id: 6, title: "main_7_item61", text: "main_7_item62", active: false },
        { id: 7, title: "main_7_item71", text: "main_7_item72", active: false },
        { id: 8, title: "main_7_item81", text: "main_7_item82", active: false },
    ])

    return (
        <div className={[classes.faq, ""].join(" ")}>
            <div className={[classes.faqBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.faqContent, ""].join(" ")}>
                        <h2 className={[classes.faqContentTitle, "font-64"].join(" ")}>
                            <Trans>main_7_1</Trans>
                        </h2>
                        <p className={[classes.faqContentSubTitle, "font-16"].join(" ")}>
                            <Trans>main_7_2</Trans>
                        </p>
                        {items.map((i) => {
                            return (
                                <div key={i.id} className={i.active ? [classes.faqItem, classes.active, ""].join(" ") : [classes.faqItem, ""].join(" ")}
                                    onClick={(() => {
                                        setItems(items.map((subI) => {
                                            if (i.id === subI.id) {
                                                return { ...subI, active: !subI.active }
                                            } else {
                                                return { ...subI, active: false }
                                            }
                                        }))
                                    })}
                                >
                                    <div className={[classes.faqItemPlus, ""].join(" ")}>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <h3 className={[classes.faqItemTitle, "font-16"].join(" ")}>
                                        <Trans>{i.title}</Trans>
                                    </h3>
                                    <p className={[classes.faqItemText, "font-16"].join(" ")}>
                                        <Trans>{i.text}</Trans>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq