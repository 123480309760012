import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/VacancyPage/Banner/Banner";
import SubBanner from "../../components/VacancyPage/SubBanner/SubBanner";
import Overview from "../../components/VacancyPage/Overview/Overview";
import SubSecondBanner from "../../components/VacancyPage/SubSecondBanner/SubSecondBanner";
import SecondOverview from "../../components/VacancyPage/SecondOverview/SecondOverview";
import Footer from "../../components/GlobalComponents/Footer/Footer"
import Form from "../../components/Form/Form";


const HomePage = () => {
  const [formOpened, setFormOpened] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <div className="ovf-hidden">
        <Header formOpened={formOpened} setFormOpened={setFormOpened} />
        <Banner formOpened={formOpened} setFormOpened={setFormOpened} />
        <SubBanner formOpened={formOpened} setFormOpened={setFormOpened} />
        <Overview />
        <SubSecondBanner formOpened={formOpened} setFormOpened={setFormOpened} />
        <SecondOverview />
        <Footer />

        <Form formOpened={formOpened} setFormOpened={setFormOpened} />

      </div>
    </HelmetProvider>
  );
};

export default HomePage;
