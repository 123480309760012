import { useEffect, useState, useRef } from "react";
import classes from "./Banner.module.scss"
import img1 from "../../../assets/img/main/1/1.png"
import img2 from "../../../assets/img/main/1/2.png"
import Button from "../../GlobalComponents/Button/Button"

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation, Trans } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const Banner = ({ formOpened, setFormOpened }) => {
    const { t } = useTranslation();
    gsap.config({
        force3D: true,
    });

    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);
    useEffect(() => {

        gsap.timeline().to(monetRef1.current, {
            y: 0,
            x: 0,
            delay: 0.3,
            scale: 1,
            duration: 1
        });
        gsap.timeline().to(monetRef2.current, {
            y: 0,
            x: 0,
            delay: 0.3,
            scale: 1,
            duration: 1
        });

    }, []);



    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className={[classes.bannerImg, ""].join(" ")} ref={monetRef2}>
                    <img src={img1} alt="Vacancies at Systema" />
                </div>
                <div className="container">
                    <div className={[classes.bannerContentWrap, ""].join(" ")}>
                        <div className={[classes.bannerContent, ""].join(" ")}>
                            <h1 className={[classes.bannerContentTitle, "font-64"].join(" ")}>
                                <Trans>vacancy_1_1</Trans>
                            </h1>
                            <p className={[classes.bannerContentSubtitle, "font-16"].join(" ")}>
                                <Trans>vacancy_1_2</Trans>
                            </p>
                            <p className={[classes.bannerContentText, "font-24"].join(" ")}>
                                <Trans>vacancy_1_3</Trans>
                            </p>
                            <div className={[classes.bannerBtn, ""].join(" ")}>
                                <Button setFormOpened={setFormOpened}><Trans>apply_btn</Trans></Button>
                            </div>
                        </div>
                        <div className={[classes.bannerContentImg, ""].join(" ")} ref={monetRef1}>
                            <img src={img2} alt="Vacancies at Systema" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Banner