import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import $ from "jquery";
import logo from "../../../assets/img/logo.png";

const languages = [
  { value: "en", label: "EN" },
  { value: "de", label: "DE" },
  { value: "tr", label: "TR" },
  { value: "ar", label: "AR" },
  { value: "es", label: "ES" },
  { value: "it", label: "IT" },
  { value: "pl", label: "PL" },
  { value: "ru", label: "RU" },
];

const Header = ({ formOpened, setFormOpened }) => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const setLocale = (lang) => {
    i18n.changeLanguage(lang);
    navigate(`/${lang}/${_.last(pathname.split("/"))}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale("en");
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
      $(headerRef.current).removeClass(classes.lightBg);
    }
  };

  useEffect(() => {
    listenScroll();
  }, [burgerOpened]);
  useEffect(() => {
    $(headerRef.current).removeClass(classes.lightBg);
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (burgerOpened) {
      $("body").addClass("lock");
    } else {
      $("body").removeClass("lock");
    }
  }, [burgerOpened]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={"/"}>
              <img src={logo} alt="" />
            </Link>
          </div>

          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <p 
              onClick={() => {setFormOpened(!formOpened)}} 
              className={[classes.headerRowRightLink, "font-16"].join(" ")} 
            >
              <Trans>{t('header_item_1')}</Trans>
            </p>
            <Link 
              className={[classes.headerRowRightLink, "font-16"].join(" ")} 
              to={"/vacancy"}
            >
              <Trans>header_item_2</Trans>
            </Link>
            <div className={burgerOpened ? [classes.mobContentBurger, classes.active, ""].join(" ") : [classes.mobContentBurger, ""].join(" ")}>
              <p 
                onClick={() => {setFormOpened(!formOpened)}} 
                className={[classes.headerRowRightLink, classes.mob, "font-16"].join(" ")} 
              >
                <Trans>header_item_1</Trans>
              </p>
              <Link 
                className={[classes.headerRowRightLink, classes.mob, "font-16"].join(" ")} 
                to={"/vacancy"}
              >
                <Trans>header_item_2</Trans>
              </Link>
            </div>

            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
