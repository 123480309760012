import gsap from "gsap";
import { useEffect, useState, useRef } from "react";
import classes from "./Why.module.scss"
import img1 from "../../../assets/img/HomePage/4/1.png"
import img2 from "../../../assets/img/HomePage/4/2.png"
import img3 from "../../../assets/img/HomePage/4/3.png"
import img4 from "../../../assets/img/HomePage/4/4.png"
import { useTranslation, Trans } from "react-i18next";

import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});
const Why = () => {
    const { t } = useTranslation();

    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);

    const cardRef_011 = useRef(null);
    const cardRef_022 = useRef(null);
    const cardRef_033 = useRef(null);
    const cardRef_044 = useRef(null);

    useEffect(() => {
        gsap.timeline().fromTo(cardRef_01.current, {
            y: 210,
        }, {
            y: 0,
            scrollTrigger: {
                trigger: cardsRef.current,
                end: "bottom bottom",
                scrub: 5,
            }
        });
        gsap.timeline().fromTo(cardRef_02.current, {
            y: 150,
        }, {
            y: 0,
            scrollTrigger: {
                trigger: cardsRef.current,
                end: "bottom bottom",
                scrub: 5,
            }
        });
        gsap.timeline().fromTo(cardRef_03.current, {
            y: 240,
        }, {
            y: 0,
            scrollTrigger: {
                trigger: cardsRef.current,
                end: "bottom bottom",
                scrub: 5,
            }
        });
        gsap.timeline().fromTo(cardRef_04.current, {
            y: 145,
        }, {
            y: 0,
            scrollTrigger: {
                trigger: cardsRef.current,
                end: "bottom bottom",
                scrub: 5,
            }
        });



        gsap.timeline().fromTo(cardRef_011.current, {
            y: 0,
        }, {
            y: -170,
            scrollTrigger: {
                trigger: document.querySelector("#explore"),
                start: "top center",
                end: "top top",
                scrub: 3,
            }
        });
        gsap.timeline().fromTo(cardRef_022.current, {
            y: 0,

        }, {
            y: -130,

            scrollTrigger: {
                trigger: document.querySelector("#explore"),
                start: "top center",
                end: "top top",
                scrub: 3,
            }
        });
        gsap.timeline().fromTo(cardRef_033.current, {
            y: 0,
        }, {
            y: -150,
            scrollTrigger: {
                trigger: document.querySelector("#explore"),
                start: "top center",
                end: "top top",
                scrub: 3,
            }
        });
        gsap.timeline().fromTo(cardRef_044.current, {
            y: 0,
        }, {
            y: -140,
            scrollTrigger: {
                trigger: document.querySelector("#explore"),
                start: "top center",
                end: "top top",
                scrub: 3,
            }
        });

    }, [])


    return (
        <div className={[classes.why, "mb"].join(" ")} ref={cardsRef}>
            <div className={[classes.whyBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.whyRow, ""].join(" ")}>
                        <div className={[classes.whyRowLeft, ""].join(" ")}>
                            <h2 className={[classes.whyRowLeftTitle, "font-64"].join(" ")}>
                                <Trans>main_4_1</Trans>
                            </h2>
                            <p className={[classes.whyRowLeftText, "font-16"].join(" ")}>
                                <Trans>main_4_2</Trans>
                            </p>
                            <p className={[classes.whyRowLeftText, "font-16"].join(" ")} id="explore">
                                <Trans>main_4_3</Trans>
                            </p>
                        </div>
                        <div className={[classes.whyRowRight, ""].join(" ")}>
                            <div className={[classes.whyRowRightImgs, ""].join(" ")}>
                                <div className={[classes.whyRowRightImgsImg1, ""].join(" ")} ref={cardRef_01}>
                                    <img src={img1} alt="Why Choose Sistema?" ref={cardRef_011} />
                                </div>
                                <div className={[classes.whyRowRightImgsImg2, ""].join(" ")} ref={cardRef_02}>
                                    <img src={img2} alt="Why Choose Sistema?" ref={cardRef_022} />
                                </div>
                                <div className={[classes.whyRowRightImgsImg3, ""].join(" ")} ref={cardRef_03}>
                                    <img src={img3} alt="Why Choose Sistema?" ref={cardRef_033} />
                                </div>
                                <div className={[classes.whyRowRightImgsImg4, ""].join(" ")} ref={cardRef_04}>
                                    <img src={img4} alt="Why Choose Sistema?" ref={cardRef_044} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Why