import React, { useEffect, useRef } from "react";
import classes from './SecondOurValues.module.scss';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
import { useTranslation, Trans } from "react-i18next";
import img1 from "../../../assets/img/HomePage/6/1.png"
import img2 from "../../../assets/img/HomePage/6/2.png"
import img3 from "../../../assets/img/HomePage/6/3.png"
import img4 from "../../../assets/img/HomePage/6/4.png"
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const SecondOurValues = () => {

    const { t } = useTranslation();

    const bodyRef = useRef(null);
    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: "54%",
            text: 'main_6_2',
            img: img1
        },
        {
            ref: cardRef_02,
            title: "36%",
            text: 'main_6_3',
            img: img2
        },
        {
            ref: cardRef_03,
            title: "7%",
            text: 'main_6_4',
            img: img3
        },
        {
            ref: cardRef_04,
            title: "3%",
            text: 'main_6_5',
            img: img4
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: -100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 60,
                x: 20,
            }, {
                y: 0,
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 60,
                x: -20,
            }, {
                y: 0,
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                x: 100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: cardsRef.current,
                    end: "bottom bottom",
                    scrub: 3,
                }
            });
            // gsap.timeline().fromTo(cardRef_04.current, {
            //     x: 100,
            // }, {
            //     x: 0,
            //     scrollTrigger: {
            //         trigger: cardsRef.current,
            //         end: "bottom bottom",
            //         scrub: 3,
            //     }
            // });
        } else {
            gsap.fromTo(cardRef_01.current, {
                x: -100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_01.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_02.current, {
                x: 100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_02.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_03.current, {
                x: -100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_03.current,
                    start: "bottom bottom",
                }
            });
            gsap.fromTo(cardRef_04.current, {
                x: 100,
                opacity: 0,
            }, {
                x: 0,
                opacity: 1,
                duration: 1,
                scrollTrigger: {
                    trigger: cardRef_04.current,
                    start: "bottom bottom",
                }
            });
        }
    }, []);
    return (
        <section className="container mt mb">
            <div ref={bodyRef} className={classes.ourValues}>

                <h2 className={[classes.title, 'font-64-b'].join(' ')}>
                    <Trans>main_6_1</Trans>
                </h2>
                <div ref={cardsRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <div className={[classes.cardIcon, ""].join(" ")}>
                                <img src={card.img} alt="Investors and Shareholders" />
                            </div>

                            <h3 className={[classes.cardTitle, "font-24"].join(" ")}>
                                {card.title}
                            </h3>
                            <p className={[classes.cardText, 'font-16-b'].join(' ')}>
                                <Trans>{card.text}</Trans>

                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default SecondOurValues;
