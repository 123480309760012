import React from 'react'
import classes from "./SecondOverview.module.scss"
import { useTranslation, Trans } from "react-i18next";

const SecondOverview = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.overview, "mb"].join(" ")}>
            <div className={[classes.overviewBody, ""].join(" ")}>
                <div className="container">

                    <div className={[classes.overviewRow, ""].join(" ")}>

                        <div className={[classes.overviewRowLeft, ""].join(" ")}>
                            <h2 className={[classes.overviewTitle, "font-120"].join(" ")}>
                                <Trans>vacancy_5_left1</Trans>
                            </h2>
                            <h2 className={[classes.overviewRowRightTitle, "font-24"].join(" ")}>
                                <Trans>vacancy_5_left2</Trans>
                            </h2>
                            <p className={[classes.overviewRowLeftText, "font-16"].join(" ")}>
                                <Trans>vacancy_5_left3</Trans>

                            </p>

                        </div>
                        <div className={[classes.overviewRowRight, ""].join(" ")}>

                            <p className={[classes.overviewRowRightText, "font-16"].join(" ")}>
                                <Trans>vacancy_5_right1</Trans>
                            </p>
                            <h3 className={[classes.overviewRowRightSubTitle, "font-24"].join(" ")}>
                                <Trans>vacancy_5_right2</Trans>
                            </h3>
                            <ul className={[classes.overviewRowRightList, ""].join(" ")}>
                                <li className={[classes.overviewRowRightItem, "font-16"].join(" ")}>
                                    <Trans>vacancy_5_right3</Trans>
                                </li>
                                <li className={[classes.overviewRowRightItem, "font-16"].join(" ")}>
                                    <Trans>vacancy_5_right4</Trans>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondOverview