import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Management.module.scss";
import { Trans, useTranslation } from "react-i18next";
import img1 from "../../../assets/img/HomePage/3/1.png"
import arrow from "../../../assets/img/HomePage/3/left.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const reviews = [
  {
    title: "main_3_item11",
    text: `main_3_item12`,
  },
  {
    title: "main_3_item21",
    text: `main_3_item22`,
  },
  {
    title: "main_3_item31",
    text: `main_3_item32`,
  },
  {

    title: "main_3_item41",
    text: `main_3_item42`,
  },
  {
    title: "main_3_item51",
    text: `main_3_item52`,
  },
  {
    title: "main_3_item61",
    text: `main_3_item62`,
  },
];

const Management = () => {
  const [width, setWidth] = useState();
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState({});
  const swiperWrapRef = useRef(null);
  const ref1 = useRef(null)
  useEffect(() => {

    setTimeout(() => {
      gsap.timeline().to(swiperWrapRef.current, {
        scrollTrigger: {
          trigger: swiperWrapRef.current,
          end: "bottom bottom",
          scrub: 3,
        },
        x: 0,
        autoAlpha: 1,
      });
    }, 300);

  }, []);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <section className="mt container mb">
      <div className={classes.reviews}>
        <div className={[classes.managementImg, ""].join(" ")} ref={swiperWrapRef}>
          <img src={img1} alt="Management Structure" />
        </div>

        <h2 className={[classes.managementTitle, "font-64"].join(" ")}>
          <Trans>main_3_1</Trans>
        </h2>
        <p className={[classes.managementSubTitle, "font-24"].join(" ")}>
          <Trans>main_3_2</Trans>
        </p>

        <div className={classes.swiperWrap}>
          <Swiper
            modules={[Navigation]}
            className="homeReviewsSwiper no-select"
            spaceBetween={60}
            slidesPerView={1}
            grabCursor
            pagination={{ clickable: true }}
            onInit={(e) => {
              setSwiper(e);
            }}
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              700: {
                slidesPerView: 2,
              },
              1220: {
                slidesPerView: 3,
              },
            }}
          >
            {reviews.map((card, index) => (
              <SwiperSlide key={index} className="homeReviewsSwiperSlide">
                <div className={classes.card}>
                  <div className={classes.cardHeader}>

                    <div className={classes.cardHeaderContent}>
                      <p className={[classes.cardTitle, "font-16"].join(" ")}>
                        <Trans>{card.title}</Trans>
                      </p>

                    </div>
                  </div>
                  <p className={[classes.cardText, "font-16"].join(" ")}>
                    <Trans>{t(card.text)}</Trans>
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>
        <div className={classes.navigation}>
          <img
            onClick={() => swiper.slidePrev()}
            className={[classes.nav, classes.nav_left].join(" ")}
            src={arrow}
            alt=""
          />
          <img
            onClick={() => swiper.slideNext()}
            className={[classes.nav, classes.nav_right].join(" ")}
            src={arrow}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Management;
