import logo from '../assets/img/logo.png';
import img1 from '../assets/img/main/1/1.png';
import img2 from '../assets/img/main/1/2.png';
import img3 from '../assets/img/HomePage/Banner/bg.png';
import img4 from '../assets/img/HomePage/Banner/bg_mob.png';
import img5 from '../assets/img/HomePage/Banner/object.png';
import img6 from '../assets/img/HomePage/Banner/sphere.png';

export const images = [
    logo,
    img1, img2, img3, img4, img5, img6,

];

