import React from 'react'
import classes from "./ThankPage.module.scss"
import Button from '../../components/GlobalComponents/Button/Button'
import { Link } from 'react-router-dom'
const ThankPage = () => {
    return (
        <div className={[classes.thankpage, ""].join(" ")}>
            <div className={[classes.thankpageBody, ""].join(" ")}>
                <div className={[classes.thankpageContent, ""].join(" ")}>
                    <h2 className={[classes.thankpageContentTitle, "font-36"].join(" ")}>
                        Спасибо за регистрацию.
                    </h2>
                    <p className={[classes.thankpageContentText, "font-20"].join(" ")}>
                        Наш менеджер свяжется с вами в ближайшее время.
                    </p>
                    <Link to={"/"} className={[classes.thankpageContentBtn, ""].join(" ")}>
                        <Button login={"true"}>На главную</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ThankPage