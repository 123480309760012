import HomePage from '../pages/HomePage/HomePage';
import VacancyPage from '../pages/VacancyPage/VacancyPage';
import ThankPage from '../pages/ThankPage/ThankPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/vacancy', element: VacancyPage },
    { path: '/thankyou', element: ThankPage },
    { path: '/:lang', element: HomePage },
    { path: '/:lang/vacancy', element: VacancyPage },
    { path: '/:lang/thankyou', element: ThankPage },
    { path: '*', element: HomePage },
];

export const headerRoutes = [
];
